* {
  font-family: 'Dosis', sans-serif;
  background-color: #fbf7f4;
  /* font-weight: 200, 300, 400, 500 */
}

a {
  color: black;
  text-decoration: none;
}

a:visited {
  color: black;
}

a:hover {
  color: #8f6894;
}

/* header */

.header {
  min-height: 8rem;
  padding: 0 10vw;
  align-items: center;
}

.nav-logo {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.nav-logo img{
  width: 10vw;
  max-width: 69px;
  height: auto;
  margin-right: 1rem;
}

.header-nav {
  display: flex;
  margin-left: auto;
  margin-top: 2rem;
}

.header-nav a {
  margin: 10px;
  text-decoration: none;
}

/* footer */

.footer {
  min-height: 4rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social {
  margin: 10px;
}

.copyright {
  margin-top: 25px;
  margin-bottom: 2rem;
  color: #8f8f8f;
  font-weight: 300;
}

/* home page */

.home {
  position: relative;
  width: 80vw;
}

.home-sloganText {
  margin: 0;
  width: 80vw;
  height: 25vh;
  margin-top: 20vh;
  margin-bottom: 100px;
}

.home-sloganText::after {
  content: '|';
  animation: sloganCursor 2s infinite step-start;
}

@keyframes sloganCursor {
  50% {
    opacity: 0;
  }
}


.home-showreel {
  max-width: 100%;
  height: 50vh;
  max-height: 560px;
  margin-bottom: 5em;
  border-radius: 10px;
}

.home-vidRow {
  margin-bottom: 3rem;
  justify-content: center;
}

.home-vidContainer {
  position: relative;
  display: flex;
}

.home-vidThumbnail {
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.home-vidPreview {
  width: 100%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: all 1s cubic-bezier(.22,1,.36,1);
  z-index: 2;
}

.home-vidPreview:hover {
  opacity: 1;
}

.home-vidTitle {
  position: absolute;
  bottom: 5%;
  background-color: transparent;
  left: 5%;
  z-index: 1;
  color: rgb(251, 247, 244, .8);
}

/* about page */

.about-container {
  width: 80vw;
}

.about-studioBioCol {
  padding: 3rem 2rem 5rem 1.5rem;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-crewCol {
  display: flex;
}

.about-playlist {
  display: flex;
  margin: 2rem 2rem 0 auto;
}

.about-footNote {
  font-size: 0.8em;
  color: #8080809f;
}

.about-crewContainer {
  display: block;
}

.bruh {
  border: 1px solid red;
}

.about-crewContainer-bigScreen {
  display: flex;
  margin: 5% auto 5% 0;
}

.about-profileCard {
  display: flex;
  align-items: center;
  margin: 2rem 2rem 2rem 0;
}

.about-headshot {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-radius: 10px;
}

.about-headshot-bigScreen {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-right: 4rem;
  border-radius: 10px;
}

.about-cardInfo {
  font-size: .8em;
  padding: 20px 0;
  width: 40vw;
}

.about-cardInfo-bigScreen {
  padding: 0 10px;
  width: 20vw;
  margin-right: 2rem;
}

/* works page */

.works-row {
  margin-bottom: 2rem;
  justify-content: center;
}

.works-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
} 

.works-title {
  text-align: start;
  font-weight: 500;
  font-size: 1.5rem;
  pointer-events: none;
}

/* work detail page */

.videoDetails-container {
  display: flex;
  flex-direction: column;
  max-width: 70vw;
}

.videoDetails-mainVideo {
  max-width: 100%;
  max-height: auto;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {

  .videoDetails-main {
    max-width: 100%;
    height: auto;
  }

  .videoDetails-container {
    max-width: 100vw;
  }
  
}

.videoDetails-info {
  padding: 1rem 1rem 2rem 0.25rem;
  font-size: 1.25rem;
}

.videoDetails-btsCol {
  max-width: 100%;
  padding: 0px;
}

.videoDetails-stills {
  max-width: 100%;
  margin-top: 2rem;
}